// const apiURL = "http://localhost:3000";
const apiURL = "/api";

export const urls = {
  base: apiURL,
  signinUrl: `${apiURL}/signin`,
  logoutUrl: `${apiURL}/logout`,
  // signupUrl: `signup`,
  forgotPassUrl: `${apiURL}/forgot-password`,
  resetPassUrl: `${apiURL}/reset-password`,
  updatePassUrl: `${apiURL}/update-password`,
  userData: `${apiURL}/user-data`,
  list: `${apiURL}/list`,
  manage: `${apiURL}/manage`,
  create: `${apiURL}/create`,
  update: `${apiURL}/update`,
};
