import { Modal } from "bootstrap";
import { urls } from "./urls.js";
import { common } from "./common.js";
import { auth } from "./auth.js";

const renderUserList = (emailList, usersEl) => {
  let htmlLogins = "";
  emailList.forEach((el) => {
    htmlLogins += `
        <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center" data-email="${el.email}">
          <div class="ms-2 me-auto">
            <div class="fw-bold">${el.name}</div>
            <div>${el.email}</div>
          </div>
            <i class="bi bi-person-x-fill me-1 text-danger" style="font-size: 1.5rem"></i>
        </li>
    `;
  });
  usersEl.innerHTML = htmlLogins;
};

const daysToExpire = (userDate) => {
  const now = new Date();
  const userLimitDate = new Date(userDate);
  const miliDiff = userLimitDate - now;
  return Math.floor(miliDiff / (1000 * 60 * 60 * 24));
};

const updateBadge = (data) => {
  document.getElementById(
    "used-badge"
  ).innerText = `${data.logins.length}/${data.qtd}`;
};

const createUTCDate = (value) => {
  const [year, month, day] = value.split("-").map(Number);
  return new Date(Date.UTC(year, month - 1, day, 23, 59, 59));
};

// renderiza lista de business para admins
const updateAdminUserList = async () => {
  const allUsers = await common.getData(urls.list);
  if (allUsers.success && allUsers.data.length > 0) {
    let htmlUsers = "";
    allUsers.data.forEach((el) => {
      const userDays = daysToExpire(el.limitDate);
      let statusIcon = "";
      userDays >= 0
        ? (statusIcon = "bi-check-circle text-success")
        : (statusIcon = "bi-x-circle text-danger");
      htmlUsers += `
                <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
                  <div class="ms-2 me-auto">
                    <div class="fw-bold">${el.email}</div>
                  </div>
                  <div class="me-2">${userDays >= 0 ? userDays : 0}d</div>
                  <div class="me-2">${el.used}/${el.qtd}</div>
                  <i class="bi ${statusIcon} me-1" style="font-size: 1.5rem"></i>
                </li>
              `;
    });
    document.getElementById("business").innerHTML = htmlUsers;
  }
  document.getElementById(
    "business-badge"
  ).innerHTML = `${allUsers.data.length}`;
};

const renderAdminResult = (message, div, alert = "alert-danger") => {
  const resultDiv = document.getElementById(div);
  resultDiv.classList.remove("alert-success");
  resultDiv.classList.remove("alert-danger");
  resultDiv.classList.remove("d-none");
  resultDiv.classList.add(alert);
  resultDiv.textContent = message;
  setTimeout(function () {
    resultDiv.classList.add("d-none");
  }, 3000);
};

const updateOptions = (selectList, business) => {
  const option = document.createElement("option");
  option.value = `${business.name} - ${business.email}`;
  option.dataset.userId = business._id;
  selectList.appendChild(option);
};

export const user = {
  userAreaMessage: () => {
    document.getElementById("user-area").innerHTML = common.renderMessage(
      `Você precisa estar logado para acessar esta área, <a href="index.html">clique aqui</a> para entrar!`
    );
  },

  adminAreaMessage: () => {
    document.getElementById("admin-area").innerHTML = common.renderMessage(
      `Você precisa estar logado e ter permissões para fazer isso!`
    );
  },

  userData: async () => {
    const userProfile = await common.getData(urls.userData);
    if (userProfile.success) {
      return userProfile;
    } else {
      localStorage.removeItem("logged");
      common.mainContent.innerHTML = `
      <div class="container">
        <div class="alert alert-danger" role="alert">
          ${userProfile.message}
        </div>
      </div>
    `;
    }
  },

  renderUserArea: async () => {
    const userProfile = await user.userData();
    if (userProfile && userProfile.data) {
      let emailToRemove = "";
      document.getElementById(
        "batch-link"
      ).href = `mailto:automacoes@transferenglish.com?subject=Cadastro em lote para a empresa ${userProfile.data.name.toUpperCase()}.`;
      const limitDateStr = new Date(userProfile.data.limitDate);
      const myDaysToExpire = daysToExpire(userProfile.data.limitDate);
      const localDateStr = limitDateStr.toLocaleDateString("pt-br");
      const userInfoBadge = document.getElementById("userstatus");
      if (myDaysToExpire >= 0) {
        userInfoBadge.classList.add("alert-success");
      } else {
        userInfoBadge.classList.add("alert-danger");
      }
      document.getElementById("logged-business").innerText =
        userProfile.data.name;
      userInfoBadge.innerHTML = `
        Total de Alunos Contratados: ${userProfile.data.qtd}<br />
        Acesso liberado até: ${localDateStr}
      `;
      // admin
      if (userProfile.data.role === "admin") {
        const selectList = document.getElementById("datalistOptions");
        document.getElementById("admin-btn").classList.remove("d-none");
        await updateAdminUserList();

        // cadastro de business
        const newBusinessForm = document.getElementById("newuserform");
        const today = new Date();
        today.setMonth(today.getMonth() + 1); // um mes de acesso
        const newUserDateLimit = document.getElementById("dataNovoBusiness");
        newUserDateLimit.value = today.toISOString().split("T")[0]; // preenche com 30d +
        newBusinessForm.addEventListener("submit", async (e) => {
          e.preventDefault();
          const newUserName = document.getElementById("nomeDaEmpresa");
          const newUserEmail = document.getElementById("emailDaEmpresa");
          const newUserLogins = document.getElementById("qtdAcessos");
          if (!newUserName.value)
            return renderAdminResult(
              "Empresa precisa ter um nome!",
              "newUserResult"
            );
          if (!newUserEmail.value)
            return renderAdminResult(
              "É necessário um email para o admin da empresa.",
              "newUserResult"
            );
          if (!newUserLogins.value || newUserLogins.value <= 0)
            return renderAdminResult(
              "É necessário uma quantidade de logins maior que zero.",
              "newUserResult"
            );
          const newUserISODate = createUTCDate(newUserDateLimit.value);
          const nowTest = new Date();
          if (newUserISODate <= nowTest)
            return renderAdminResult(
              "Não pode ser uma data no passado!",
              "newUserResult"
            );
          const newUserButton = document.getElementById("new-user-submit");
          newUserButton.disabled = true;
          const newUser = {};
          newUser.email = newUserEmail.value;
          newUser.name = newUserName.value;
          newUser.qtd = newUserLogins.value;
          newUser.limitDate = newUserISODate.toISOString();
          //common.renderSpinner(document.getElementById("business"));
          const response = await common.postData(`${urls.create}`, newUser);
          if (response.success) {
            await updateAdminUserList();
            renderAdminResult(
              response.message,
              "newUserResult",
              "alert-success"
            );
            newUserEmail.value = "";
            newUserName.value = "";
            newUserLogins.value = "";
            // atualizando a lista inserindo novo usuário recem criado
            updateOptions(selectList, response.data);
          } else {
            renderAdminResult(response.message, "newUserResult");
          }
          newUserButton.disabled = false;
        });

        // atualização de business
        const allUsers = await common.getData(urls.list);
        if (allUsers.success && allUsers.data.length > 0) {
          allUsers.data.forEach((business) => {
            updateOptions(selectList, business);
          });
          // selecionando elementos
          const inputList = document.getElementById("searchUpdate");
          const businessNameUpdate =
            document.getElementById("businessNameUpdate");
          const businessEmailUpdate = document.getElementById(
            "businessEmailUpdate"
          );
          const businessQtdUpdate =
            document.getElementById("businessQtdUpdate");
          const limitDateUpdate = document.getElementById("limitDateUpdate");
          const updateBusinessForm =
            document.getElementById("updateBusinessForm");
          const businessUpdateButton = document.getElementById(
            "businessUpdateButton"
          );
          const clearUpdateButton =
            document.getElementById("clearUpdateButton");

          const clearUpdateForm = (includeInput = false) => {
            if (includeInput) inputList.value = "";
            businessNameUpdate.value = "";
            businessEmailUpdate.value = "";
            businessQtdUpdate.value = "";
            limitDateUpdate.value = "";
            businessNameUpdate.disabled = true;
            businessEmailUpdate.disabled = true;
            businessQtdUpdate.disabled = true;
            limitDateUpdate.disabled = true;
            businessUpdateButton.disabled = true;
          };

          clearUpdateButton.addEventListener("click", (e) => {
            clearUpdateForm(true);
          });

          let currentUserId;
          // preenchendo form
          inputList.addEventListener("input", async (e) => {
            const selectedOption = Array.from(selectList.options).find(
              (option) => option.value === e.target.value
            );
            if (selectedOption) {
              const userInfo = await common.getData(
                `${urls.base}/${selectedOption.dataset.userId}`
              );
              if (userInfo.success && userInfo.data) {
                // atualizando campos
                currentUserId = userInfo.data._id;
                businessNameUpdate.value = userInfo.data.name;
                businessEmailUpdate.value = userInfo.data.email;
                businessQtdUpdate.value = userInfo.data.qtd;
                const businessLimitDate = new Date(userInfo.data.limitDate);
                limitDateUpdate.value = businessLimitDate
                  .toISOString()
                  .split("T")[0];
                // desabilitando campos e botão
                businessNameUpdate.disabled = false;
                businessEmailUpdate.disabled = false;
                businessQtdUpdate.disabled = false;
                limitDateUpdate.disabled = false;
                businessUpdateButton.disabled = false;
              }
            } else {
              clearUpdateForm();
            }
          });
          // finalmente atualizando
          updateBusinessForm.addEventListener("submit", async (e) => {
            e.preventDefault();
            if (!businessNameUpdate.value)
              return renderAdminResult(
                "Empresa precisa ter um nome!",
                "businessUpdateResult"
              );
            if (!businessEmailUpdate.value)
              return renderAdminResult(
                "É necessário um email para o admin da empresa.",
                "businessUpdateResult"
              );
            if (!businessQtdUpdate.value || businessQtdUpdate.value <= 0)
              return renderAdminResult(
                "É necessário uma quantidade de logins maior que zero.",
                "businessUpdateResult"
              );
            const updateUserISODate = createUTCDate(limitDateUpdate.value);
            const nowTest2 = new Date();
            if (updateUserISODate <= nowTest2)
              return renderAdminResult(
                "Não pode ser uma data no passado!",
                "businessUpdateResult"
              );
            businessUpdateButton.disabled = true;
            const updateUser = {};
            updateUser.name = businessNameUpdate.value;
            updateUser.email = businessEmailUpdate.value;
            updateUser.qtd = businessQtdUpdate.value;
            updateUser.limitDate = updateUserISODate.toISOString();
            // common.renderSpinner(document.getElementById("business"));
            const response = await common.postData(
              `${urls.update}/${currentUserId}`,
              updateUser,
              "PATCH"
            );
            if (response.success) {
              await updateAdminUserList();
              updateBadge(response.data);
              renderAdminResult(
                response.message,
                "businessUpdateResult",
                "alert-success"
              );
              // atualizando a lista para update com novos dados
              updateOptions(selectList, response.data);
            } else {
              renderAdminResult(response.message, "businessUpdateResult");
            }
            businessUpdateButton.disabled = false;
          });
        }
      } else {
        // removendo form user não admin
        // TODO: adicionar form se for admin e não remover quando "não admin"
        document.getElementById("admin-area").remove();
      }

      // atualizando badge no loading
      updateBadge(userProfile.data);

      // administrando acessos de usuários
      const myUsers = document.getElementById("users");
      const myModalEl = document.getElementById("myModal");
      const modalTitle = document.getElementById("staticBackdropLabel");
      const modalBody = document.querySelector(".modal-body");
      const btnAction = document.getElementById("btn-action");
      const btnClose = document.getElementById("modal-exit");
      const myModal = new Modal(myModalEl, {
        keyboard: false,
        backdrop: false,
      });

      // se tem acessos renderiza
      if (userProfile.data.logins.length > 0)
        renderUserList(userProfile.data.logins, myUsers);

      btnAction.addEventListener("click", async () => {
        btnAction.classList.add("d-none");
        common.renderSpinner(modalBody);
        const response = await common.postData(
          `${urls.manage}`,
          {
            email: emailToRemove,
            option: "del",
          },
          "PATCH"
        );
        if (response.success) {
          if (response.data.logins.length > 0) {
            renderUserList(response.data.logins, myUsers);
          } else {
            document.getElementById("users").innerHTML = `
              <p class="lead" style="font-style: italic">
                Você ainda não criou nenhum acesso!
              </p>`;
          }
          modalBody.innerHTML = common.renderMessage(
            response.message,
            "alert-success"
          );
          modalTitle.innerText = "Ok!";
          updateBadge(response.data);
          if (userProfile.data.role === "admin") {
            await updateAdminUserList();
          }
        } else {
          modalTitle.innerText = "Ops!";
          modalBody.innerHTML = common.renderMessage(response.message);
        }
        btnClose.innerText = "Fechar";
      });

      // removendo listener dinamicamente
      myModalEl.addEventListener("hide.bs.modal", () => {
        btnAction.removeEventListener("click", () => {
          emailToRemove = "";
        });
      });

      // adicionando listeners dinamicamente
      myUsers.addEventListener("click", (e) => {
        const clickedLi = e.target.closest("li");
        if (clickedLi) {
          emailToRemove = clickedLi.getAttribute("data-email");
          modalBody.innerHTML = `Você tem certeza que deseja remover o acesso de <span class="fw-bold">${emailToRemove}</span>?`;
          modalTitle.innerText = "Atenção";
          btnAction.innerText = "Remover";
          btnClose.innerText = "Cancelar";
          btnAction.classList.remove("d-none");
          myModal.show();
        }
      });

      // adição de acessos
      const myForm = document.getElementById("insert-form");
      myForm.addEventListener("submit", async (e) => {
        e.preventDefault();
        const newEmail = document.getElementById("new-email");
        const newName = document.getElementById("new-name");
        if (newEmail.value && newName.value) {
          const response = await common.postData(
            `${urls.manage}`,
            {
              email: newEmail.value.toLowerCase(),
              name: newName.value,
              option: "add",
            },
            "PATCH"
          );
          const resultInsertMessage = document.getElementById("result-form");
          if (response.success) {
            renderUserList(response.data.logins, myUsers);
            updateBadge(response.data);
            resultInsertMessage.innerHTML = common.renderMessage(
              response.message,
              "alert-success"
            );
            if (userProfile.data.role === "admin") {
              await updateAdminUserList();
            }
          } else {
            resultInsertMessage.innerHTML = common.renderMessage(
              response.message
            );
          }
          resultInsertMessage.classList.remove("d-none");
          newName.value = "";
          newEmail.value = "";
          setTimeout(function () {
            resultInsertMessage.classList.add("d-none");
          }, 3000);
        }
      });

      // busca por user
      const searchField = document.getElementById("search-email");
      searchField.addEventListener("input", function () {
        const searchFor = searchField.value.trim().toLowerCase();
        const usersList = myUsers.getElementsByTagName("li");
        for (const user of usersList) {
          const item = user.textContent.trim().toLowerCase();
          if (item.includes(searchFor)) {
            user.classList.remove("d-none");
          } else {
            user.classList.add("d-none");
          }
        }
      });
    } else {
      // user.userAreaMessage();
      window.location.assign("/index.html");
    }
  },

  manageUserArea: async () => {
    if (localStorage.getItem("logged") === "true") {
      await user.renderUserArea();
      await auth.registerLogout();
    } else {
      // user.userAreaMessage();
      window.location.assign("/index.html");
    }
  },

  managePolicies: () => {
    common.mainContent.insertAdjacentHTML("beforeend", common.modalPolicies);
    const modaEl = document.getElementById("modal-politicas");
    const myModal = new Modal(modaEl);
    myModal.show();
    modaEl.addEventListener("hide.bs.modal", (e) => {
      const dataAccepted = new Date().toLocaleString();
      localStorage.setItem("politicas", `aceito em ${dataAccepted}`);
    });
  },
};
