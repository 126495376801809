import { errorHandling } from "./errorHandler.js";

export const common = {
  mainContent: document.querySelector("#content"),

  modalPolicies: `
  <!-- Modal -->
  <div class="modal fade" id="modal-politicas" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="staticBackdropLabel">Política de Privacidade e Cookies</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          Este site utiliza um único cookie, que armazena somente o ID do usuário.
          Ao continuar navegando, você concorda com nossa <a href="privacidade.html">política de privacidade e uso de cookies</a>.
          Por favor, leia os <a href="termos.html">termos de uso</a> antes de prosseguir! 😀
        </div>
        <div class="modal-footer" id="choice">
          <button id="aceitar" type="button" class="btn btn-primary" data-bs-dismiss="modal">Aceitar</button>
        </div>
      </div>
    </div>
  </div>
`,

  togglePassword: () => {
    const togglePasswordBtns = document.querySelectorAll(".toggle-password");
    togglePasswordBtns.forEach((btn) => {
      btn.addEventListener("click", function (e) {
        const inputField = this.closest(".input-group").querySelector("input");
        const type =
          inputField.getAttribute("type") === "password" ? "text" : "password";
        inputField.setAttribute("type", type);
        this.querySelector("i").classList.toggle("bi-eye-slash");
        this.querySelector("i").classList.toggle("bi-eye-fill");
      });
    });
  },

  getData: async (url, method = "GET") => {
    try {
      const response = await fetch(url, {
        method: method,
        credentials: "include",
      });
      const result = await response.json();
      return result;
    } catch (error) {
      errorHandling(error);
    }
  },

  postData: async (url, dataObj, method = "POST") => {
    try {
      const response = await fetch(url, {
        method: method,
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataObj),
      });
      const result = await response.json();
      return result;
    } catch (error) {
      errorHandling(error);
    }
  },

  renderSpinner: (element) => {
    element.innerHTML = `
      <div class="container d-flex align-items-center justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Carregando...</span>
        </div>
      </div>
    `;
  },

  renderMessage: function (message, cssClass = "alert-danger") {
    return `
      <div class="alert ${cssClass}" role="alert">
        ${message}
      </div>
    `;
  },

  logUserIn: () => {
    localStorage.setItem("logged", true);
    window.location.assign("/usuario.html");
  },

  redirectUserArea: () => {
    if (
      window.location.href.includes("/forgotpass") ||
      window.location.href.includes("/resetpass") ||
      window.location.href.includes("/signin") ||
      window.location.href.includes("/index")
    ) {
      window.location.assign("/usuario.html");
    }
  },

  updateMenu: (logged = false) => {
    if (logged) {
      document.getElementById("nav-index").classList.add("d-none");
      document.getElementById("nav-user").classList.remove("d-none");
    } else {
      document.getElementById("nav-index").classList.remove("d-none");
      document.getElementById("nav-user").classList.add("d-none");
    }
  },
};
