import { Modal } from "bootstrap";
import { urls } from "./urls.js";
import { common } from "./common.js";

export const auth = {
  ///// SIGNIN START /////
  submitSigninForm: async () => {
    const form = document.getElementById("signin-form");
    form.addEventListener("submit", async (event) => {
      event.preventDefault();
      const email = form.elements.email.value;
      const password = form.elements.password.value;
      const signinStatus = document.getElementById("signin-status");
      const response = await common.postData(urls.signinUrl, {
        email,
        password,
      });
      if (response.success) {
        common.logUserIn();
        window.location.assign("/usuario.html");
      } else {
        localStorage.removeItem("logged");
        signinStatus.innerHTML = common.renderMessage(response.message);
      }
    });
  },
  ///// SIGNIN END /////

  ///// SIGNUP START /////
  submitSignupForm: async () => {
    const form = document.getElementById("signup-form");
    form.addEventListener("submit", async (event) => {
      event.preventDefault();
      const email = form.elements.email.value;
      const emailConfirm = form.elements.emailConfirm.value;
      const password = form.elements.password.value;
      const passwordConfirm = form.elements.passwordConfirm.value;
      const signupStatus = document.getElementById("signup-status");
      signupStatus.innerHTML = "";
      if (email === emailConfirm) {
        if (password === passwordConfirm) {
          if (password.length >= 8 && password.length <= 20) {
            const response = await common.postData(urls.signupUrl, {
              email,
              emailConfirm,
              password,
              passwordConfirm,
            });
            if (response.success) {
              common.logUserIn();
              window.location.assign("/usuario.html");
            } else {
              localStorage.removeItem("logged");
              signupStatus.innerHTML = common.renderMessage(response.message);
            }
          } else {
            signupStatus.innerHTML = common.renderMessage(
              `Senha precisa ter de 8 a 20 caracteres!`
            );
          }
        } else {
          signupStatus.innerHTML = common.renderMessage(`Senhas não conferem!`);
        }
      } else {
        signupStatus.innerHTML = common.renderMessage(`Emails não conferem!`);
      }
    });
  },
  ///// SIGNUP END /////

  ///// FORGOT PASSWORD START /////
  submitForgotPassForm: async () => {
    const form = document.getElementById("forgot-form");
    form.addEventListener("submit", async (event) => {
      event.preventDefault();

      const modalBody = document.querySelector(".modal-body");
      const email = form.elements.email.value;
      document.getElementById("email").value = "";
      const myModal = new Modal("#myModal", {
        keyboard: false,
        backdrop: false,
      });
      const close = document.getElementById("close");
      const redirect = document.getElementById("redirect");
      close.classList.add("d-none");
      redirect.classList.add("d-none");
      myModal.show();
      modalBody.innerHTML = `
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Carregando...</span>
        </div>
      `;
      const response = await common.postData(urls.forgotPassUrl, { email });
      if (response.success) {
        modalBody.innerHTML = common.renderMessage(
          response.message,
          "alert-success"
        );
        close.classList.remove("d-none");
        redirect.classList.remove("d-none");
      } else {
        myModal.hide();
        const forgotStatus = document.getElementById("forgot-status");
        forgotStatus.innerHTML = common.renderMessage(response.message);
      }
    });
  },
  ///// FORGOT PASSWORD END /////

  ///// RESET PASSWORD START /////
  submitResetPassForm: async () => {
    const form = document.getElementById("reset-form");
    const urlParams = new URLSearchParams(window.location.search);
    const tokenParam = urlParams.get("token");
    if (tokenParam) {
      form.elements.token.value = tokenParam;
      form.elements.password.focus();
    }
    form.addEventListener("submit", async (event) => {
      event.preventDefault();
      const token = form.elements.token.value;
      const password = form.elements.password.value;
      const passwordConfirm = form.elements.passwordConfirm.value;
      const resetStatus = document.getElementById("reset-status");
      resetStatus.innerHTML = "";
      if (password === passwordConfirm) {
        if (password.length >= 8 && password.length <= 20) {
          const response = await common.postData(
            urls.resetPassUrl,
            {
              token,
              password,
              passwordConfirm,
            },
            "PATCH"
          );
          if (response.success) {
            common.logUserIn();
            window.location.assign("/usuario.html");
          } else {
            localStorage.removeItem("logged");
            resetStatus.innerHTML = common.renderMessage(response.message);
          }
        } else {
          resetStatus.innerHTML = common.renderMessage(
            `Senha precisa ter de 8 a 20 caracteres!`
          );
        }
      } else {
        resetStatus.innerHTML = common.renderMessage(`Senhas não conferem!`);
      }
    });
  },
  ///// RESET PASSWORD END /////

  ///// SIGNUP START /////
  submitUpdatePassForm: async () => {
    if (localStorage.getItem("logged") === "true") {
      common.togglePassword();
      const form = document.getElementById("update-pass-form");
      form.addEventListener("submit", async (event) => {
        event.preventDefault();
        const password = form.elements.password.value;
        const newPassword = form.elements.newPassword.value;
        const passwordConfirm = form.elements.passwordConfirm.value;
        const updatePassStatus = document.getElementById("update-pass-status");
        updatePassStatus.innerHTML = "";
        if (newPassword === passwordConfirm) {
          if (newPassword.length >= 8 && newPassword.length <= 20) {
            const response = await common.postData(
              urls.updatePassUrl,
              {
                password,
                newPassword,
                passwordConfirm,
              },
              "PATCH"
            );
            if (response.success) {
              form.elements.password.value = "";
              form.elements.newPassword.value = "";
              form.elements.passwordConfirm.value = "";
              updatePassStatus.innerHTML = common.renderMessage(
                `Senha alterada com sucesso!`,
                "alert-success"
              );
            } else {
              updatePassStatus.innerHTML = common.renderMessage(
                response.message
              );
            }
          } else {
            updatePassStatus.innerHTML = common.renderMessage(
              `Senha precisa ter de 8 a 20 caracteres!`
            );
          }
        } else {
          updatePassStatus.innerHTML =
            common.renderMessage(`Senhas não conferem!`);
        }
      });
    }
  },
  ///// SIGNUP END /////

  ///// LOGOUT START /////
  registerLogout: async () => {
    const logoutButton = document.getElementById("btn-logout");
    const userArea = document.getElementById("user-area");
    logoutButton.addEventListener("click", async () => {
      localStorage.removeItem("logged");
      sessionStorage.clear();
      const logoutResponse = await common.getData(urls.logoutUrl);
      window.location.assign("/index.html");
      // userArea.innerHTML = common.renderMessage(logoutResponse.message);
    });
  },
  ///// LOGOUT END /////
};
