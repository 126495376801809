import "bootstrap-icons/font/bootstrap-icons.css";

import { common } from "./common.js";
import { auth } from "./auth.js";
import { user } from "./user.js";

///// POLICIES, COOKIES AND TERMS /////
if (
  !localStorage.getItem("politicas") &&
  !window.location.href.includes("/privacidade") &&
  !window.location.href.includes("/termos")
) {
  user.managePolicies();
}

///// AUTH FUNCTIONS /////
if (window.location.href.includes("/forgotpass")) auth.submitForgotPassForm();
if (window.location.href.includes("/resetpass")) {
  auth.submitResetPassForm();
  common.togglePassword();
}
if (
  window.location.pathname === "" ||
  window.location.pathname === "/" ||
  window.location.href.includes("/index")
) {
  auth.submitSigninForm();
  common.togglePassword();
}
// if (window.location.href.includes("/signup")) {
//   auth.submitSignupForm();
//   common.togglePassword();
// }

///// USER AREA ////
if (window.location.href.includes("/usuario")) {
  user.manageUserArea();
  auth.submitUpdatePassForm();
}
if (localStorage.getItem("logged") === "true") {
  common.redirectUserArea();
  common.updateMenu(true);
} else {
  common.updateMenu();
}
